/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import PollIcon from "@mui/icons-material/Poll";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Drawer, Menu, MenuItem, ListItemText } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseUrl } from "../BaseUrl/Baseurl";
import logo from '../accests/Images/logo.png'
import axios from "axios";

const drawerWidth = 240;

const iconArray = [
  <PermIdentityIcon />,
  <PollIcon />,
  <DashboardIcon />
];

function SideBar(props) {
  const token = localStorage.getItem("Token")
  const [Bk, setbk] = useState("");
  console.log(Bk, "SideBar");
  useEffect(() => {
    const GetUser = async () => {
      try {
        const res = await axios.get(`${BaseUrl}admin/users`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
  
        // Filter users based on role (replace 'admin' with the actual role you want)
        const filteredAdmins = res.data.data.filter(user => user.role === 'admin');
  
        // Assuming you want to set the first admin in the state (adjust as needed)
        setbk(filteredAdmins[0]);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    GetUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("Token")) {
      navigate('/login');
    }
  }, [navigate]);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null); 
  }
    const  handlLogout = () =>{
   setTimeout(() => {
     
     localStorage.clear("Token")
     navigate("/login")
   }, 2000);
     toast.success("Logout SuccesFully")
     };

 
  const drawer = (
    <div style={{ height: "100vh", backgroundColor: "#17202a", zIndex: 1 }}>
      <Toolbar />
      <Link to="/" className="text-decoration-none">
        <ListItemButton
          style={{ textAlign: "center", marginTop: "-50px", cursor: "pointer" }}
        >
       <img src={logo}/>
        </ListItemButton>
      </Link>
      <Divider />
      <List className="mt-5">
        {[
          { text: "User", link: "/dataUser" },
          { text: "Poll", link: "/dataedit" },
          { text: "Dashboard", link: "/" },
        ].map(({ text, link }, index) => (
          <Link to={link} key={text} className="text-decoration-none">
            <ListItem key={text} disablePadding className="font-sans">
              <ListItemButton>
                <div className="mr-4 h-10 items-center flex w-10 rounded-full">
                  <ListItemIcon className="white pl-2">
                    {iconArray[index]}
                  </ListItemIcon>
                </div>
                <ListItemText className="white" primary={text} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: "#17202a" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt="User Avatar"
              src={Bk.image}
              onClick={handleProfileClick}
              style={{ cursor: "pointer" }}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleProfileClose}
            >
              <Link to="/Profile" className="text-decoration-none">
                <MenuItem onClick={handleProfileClose}>Profile</MenuItem>
              </Link>
              <MenuItem onClick={handlLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#20283E",
              },
            }}
            open
          >
            {drawer}
          </Drawer>

          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#20283E",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
}

export default SideBar;
