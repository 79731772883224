import React
 from 'react'
import SideBar from '../../Components/SideBar'
import '../../accests/Css/Login.css'
import TableData from '../../Components/Table'
// import axios from 'axios'
// import { BaseUrl } from '../../BaseUrl/Baseurl'

export default function Data() {

  return (
    <div style={{overflowX:"hidden", height:"100vh"}} className='bg-gray-300 '>
      <SideBar/>
      <div className='lg:ml-44'>
       <TableData />
      </div>
    </div>
  )
}
