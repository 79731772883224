import React, { useState } from "react";
import "../accests/Css/Login.css";
import { useNavigate } from "react-router-dom";
import pic from "../accests/Images/lestvote.avif";
import axios from "axios";
import { BaseUrl } from "../BaseUrl/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const Navigate = useNavigate();
  const [Email, setEmail] = useState("admin@poll.com");
  const [loginPassword, setLoginPassword] = useState("12345678");

  const handleLogin = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", Email);
    formData.append("password", loginPassword);
    try {
      const res = await axios.post(`${BaseUrl}login`, formData);
      localStorage.setItem("Token", res.data.data.token);
      localStorage.setItem("profileData", JSON.stringify(res.data.data));
      console.log(res.data.data, "login data");
      setTimeout(() => {
        Navigate("/");
      }, 2000);
      toast.success(res.data.response);
    } catch (error) {
      // else{
      //   toast.error("User not allowed to Access Admin")
      // }

      console.error("Error:", error.message);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  return (
    <div className="body">
      <section className="mx-auto container py-20 b">
        <div className="grid grid-cols-1 md:grid-cols-2 rounded-xl shadow-3xl overflow-hidden">
          <div className="flex flex-col items-center justify-between gap-8 p-5 md:p-10 lg:p-14">
            <div>
              <img
                className="w-32 md:w-40 h-auto"
                src="assets/images/logo.png"
                alt=""
              />
            </div>
            <div className="mb-8">
              <h2 className="text-3xl font-bold text-pastelBlue">
                Login to Your Account
              </h2>
            </div>
            <form
              action=""
              className="w-full flex flex-col gap-5"
              onSubmit={handleLogin}
            >
              <div className="join shadow-2xl p-2 flex items-center">
                <label className="px-3 py-1 text-lg join-item text-pastelBlue border-r border-r-slate-400">
                  <i className="fa-solid fa-envelope"></i>
                </label>
                <input
                  type="email"
                  className="px-3 py-2 w-full text-sm join-item outline-none bg-transparent"
                  placeholder="Email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="join shadow-2xl p-2 flex items-center">
                <label className="px-3 py-1 text-lg join-item text-pastelBlue border-r border-r-slate-400">
                  <i className="fa-solid fa-lock"></i>
                </label>
                <input
                  type="password"
                  className="px-3 py-2 w-full text-sm join-item outline-none bg-transparent"
                  placeholder="Enter Password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
              </div>

              <button className="fill-btn btn-large" type="submit">
                Login <i className="fa-solid fa-paper-plane"></i>
              </button>
            </form>
            <div>
              <p className="text-center">2023 VideoTv, All Right Reserved</p>
            </div>
          </div>

          <div className="hidden md:block h-full">
            <img
              className="w-full  object-cover"
              style={{ height: "575px" }}
              src={pic}
              alt="Login"
            />
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export default Login;