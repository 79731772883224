import React, { useState, useMemo, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import {  useNavigate } from "react-router-dom";
import '../accests/Css/Sidebar.css';
import axios from "axios";
import { BaseUrl } from "../BaseUrl/Baseurl";

const TableData = () => {
  const token = localStorage.getItem("Token");
  const [data, setData] = useState([]);

  const GetData = async () => {
    try {
      const res = await axios.get(
        `${BaseUrl}admin/questions`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setData(res?.data?.data);
      console.log(res.data.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  useEffect(() => {
    GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Navigate = useNavigate()
  const tableData = useMemo(() => {
    return data?.map((e,index) => ({
      id: e.id,
      Title: (
        <div to={`/details/${index + 1}`} key={index} className="text-gray-500 no-underline" onClick={()=>Navigate(`/details/${e.id}` , {state:e})}>
          {e.title}
        </div>
      ),
      // Add other properties you want to display in the table
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "Title",
        header: "Title",
        size: 150,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowActions: false,
  });

  return (
    <div className="app-content content lg:ml-20 lg:mt-10" style={{ width: "90%" }}>
      <div className="content-wrapper ">
        <div className="content-header row"></div>
        <div className="content-body">
          <MaterialReactTable table={table} />
        </div>
      </div>
    </div>
  );
};

export default TableData;
