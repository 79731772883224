import React, { useState, useMemo, useEffect } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import {  useNavigate } from "react-router-dom";
import { BsPencil, BsTrash, BsEye, BsPlus } from "react-icons/bs";
import { Modal, Button, Form } from 'react-bootstrap';
import "../accests/Css/Sidebar.css";
import { BaseUrl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditPoll = () => {
  const token = localStorage.getItem("Token");
  const Navigate = useNavigate()

 

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [pollTitle, setPollTitle] = useState("");
  const [pollTitle1, setPollTitle1] = useState("");
  const [id ,setId] = useState("")
  const [data,setData] = useState([])
  console.log(id);
  const GetData =  async () =>{
    try {
      const res = await axios.get(
        `${BaseUrl}admin/questions`,
        
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  setData(res?.data?.data)
      console.log(res.data.data); 
    } catch (error) {
      console.error("Error posting question:", error);
    }
  }
  useEffect(()=>{
    GetData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const handleLogValuesEdit = async () => {
    const formdata = new FormData();
    formdata.append('title', pollTitle1);
  
    // eslint-disable-next-line array-callback-return
    answerOptions.map((answer, index) => {
      formdata.append(`options[${index}][title]`, answer);
      console.log(`${index + 1}:`, answer);
    });
  
    try {
      const res = await axios.put(
        `${BaseUrl}admin/questions/${id}`,
        formdata,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      setTimeout(() => {
        handleCloseAdd();
      }, 2000);
  
      toast.success(res.data.response);
      GetData();
      console.log(res.data);
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Failed to update question. Please try again.");
    }
  };
  
  const handleCloseAdd = () => {
    setShowAddModal(false);

    setAnswerOptions([""]);
  };

  const handleShowAdd = () => setShowAddModal(true);
  const handleCloseEdit = () => setShowEditModal(false);
  const handleShowEdit = () => setShowEditModal(true);

  const tableData = useMemo(() => {
    console.log(data ,'data');
    return data?.map((e, index) => ({
      id: index + 1,
      Title: (
        <div to={`/details/${index + 1}`} key={index} className="text-gray-500 no-underline" onClick={()=>Navigate(`/details/${e.id}` , {state:e})}>
        {setId(e.id)}
          {e.title}
        </div>
      ),
      Actions: (
        <div className="flex justify-end">
          <BsEye
            className="cursor-pointer text-blue-500 hover:underline mx-2"
            onClick={()=>Navigate(`/details/${e.id}` , {state:e})}
          />
          <BsPencil
            className="cursor-pointer text-green-500 hover:underline mx-2"
            onClick={() => handleActionClick("Edit", e.id, e.title)}
          />
          <BsTrash
            className="cursor-pointer text-red-500 hover:underline mx-2"
            onClick={() => handleActionClick("Delete",e.id)}
          />
        </div>
      ),
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
console.log(pollTitle1);
  const handleActionClick =  async (action, pollId,title) => {
    if (action === "Edit") {
      setPollTitle1(title)
      console.log(pollTitle1);
      console.log(action, pollId);
      handleShowEdit();
    }
    else{

    console.log(`${action} clicked for poll with ID ${pollId}`);
    const res = await axios.delete(
      `${BaseUrl}admin/questions/${pollId}`,
      
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
      );
      GetData()
    console.log("res--->" , res);
    if(res.data){

      toast.success(res.data.response)
    }
    else{
      toast.error("Internal Servel Error")
    }
  }

   
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "Title",
        header: "Title",
        size: 150,
      },
      {
        accessorKey: "Actions",
        header: "Actions",
        size: 200,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowActions: false,
  });
  const handleLogValues = async () => {
    console.log( pollTitle);
  
    console.log("Answer Options:");
    answerOptions.forEach((answer, index) => {
      
      console.log(`${index + 1}:`, answer);
    });
  
    const token = localStorage.getItem("Token");
  
    const formdata = new FormData()
 formdata.append('title', pollTitle)

 // eslint-disable-next-line array-callback-return
 answerOptions.map((answer, index)  => {
  formdata.append(`options[${index}][title]`, answer)
  console.log(`${index + 1}:`, answer);
});

    try {
      const res = await axios.post(
        `${BaseUrl}admin/questions`,
        formdata,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setTimeout(() => {
        handleCloseAdd()
      }, 2000);
      toast.success(res.data.response)
     GetData()
      console.log(res.data); 
      setShowEditModal(false)

    } catch (error) {
      console.error("Error posting question:", error);
    }
  };
 


  
  const handleAddAnswer = () => {
    setAnswerOptions([...answerOptions, ""]);
  };

  const handleRemoveAnswer = (index) => {
    const updatedAnswerOptions = [...answerOptions];
    updatedAnswerOptions.splice(index, 1);
    setAnswerOptions(updatedAnswerOptions);
  };

  return (
    <div className="app-content content lg:ml-20 lg:mt-10" style={{ width: "90%" }}>
      <div className="content-wrapper">
        <div className="content-header row">
          <div className="col-12 text-left mb-3 lg:ml-5 bn">
            <Button variant="" onClick={handleShowAdd} className="text-white bnb w-14" style={{ backgroundColor: "#bd2a28", boxShadow: "inset 0 0px 10px 5px #750c0c" }}>
              <BsPlus className="text-3xl" />
            </Button>
          </div>
        </div>
        <div className="content-body">
          <MaterialReactTable table={table} />
        </div>
      </div>

      <Modal show={showAddModal} onHide={handleCloseAdd} centered>
        <Modal.Header closeButton style={{ backgroundColor: "#17202a", borderBottom: "1px solid #38444d", color: "white", boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.20) inset" }}>
          <Modal.Title >Add Poll</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#17202a", boxShadow: "inset -8px 6px 122px -1px rgba(0,0,0,0.75)" }}>
          <Form>
            <Form.Group controlId="formPollTitle">
              <Form.Label className="text-white">Poll Title</Form.Label>
              <Form.Control type="text" placeholder="Enter poll title" onChange={(e) => setPollTitle(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formPollTitle" className="">
              <Form.Label className="text-white mt-3">Answer Options</Form.Label>
              {answerOptions.map((answer, index) => (
                <div key={index} className="row align-items-center">
                  <div className="col">
                    <Form.Control
                      type="text"
                      className="w-60 mb-2"
                      placeholder={`Enter Answer ${index + 1}`}
                      value={answer}
                      onChange={(e) => {
                        const updatedAnswerOptions = [...answerOptions];
                        updatedAnswerOptions[index] = e.target.value;
                        setAnswerOptions(updatedAnswerOptions);
                      }}
                    />
                  </div>
                </div>
              ))}
              <div className="row mt-3 justify-center w-full">
                <div className="col text-center">
                  <Button variant="success" className="w-50" onClick={handleAddAnswer}>+</Button>
                </div>
                <div className="col text-center">
                  <Button variant="danger" className="w-50" onClick={handleRemoveAnswer}>-</Button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#17202a", borderTop: "1px solid #38444d", justifyContent: "center" }}>
          <Button variant="" className="text-white w-52" style={{ backgroundColor: "#bd2a28", boxShadow: "inset 0 0px 10px 5px #750c0c" }} onClick={() => { handleShowAdd(); handleLogValues(); }}>
            ADD POLLS
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={showEditModal} onHide={handleCloseEdit} centered>
        <Modal.Header closeButton style={{ backgroundColor: "#17202a", borderBottom: "1px solid #38444d", color: "white" }}>
          <Modal.Title className="text-white">Edit Poll</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#17202a", boxShadow: "inset -8px 6px 122px -1px rgba(0,0,0,0.75)" }}>
          <Form>
            <Form.Group controlId="formPollTitle">
              <Form.Label className="text-white">Poll Title</Form.Label>
              <Form.Control type="text" placeholder="Enter Poll Title" onChange={(e) => setPollTitle1(e.target.value)} value={pollTitle1 ? pollTitle1 : ""} />
            </Form.Group>
            <Form.Group controlId="formPollTitle" className="">
              <Form.Label className="text-white mt-3">Answer Options</Form.Label>
              {answerOptions.map((answer, index) => (
                <div key={index} className="row align-items-center">
                  <div className="col">
                    <Form.Control
                      type="text"
                      className="w-60 mb-2"
                      placeholder={`Enter Answer ${index + 1}`}
                      value={answer}
                      onChange={(e) => {
                        const updatedAnswerOptions = [...answerOptions];
                        updatedAnswerOptions[index] = e.target.value;
                        setAnswerOptions(updatedAnswerOptions);
                      }}
                    />
                  </div>
                </div>
              ))}
              <div className="row mt-3 justify-center w-full">
                <div className="col text-center">
                  <Button variant="success" className="w-50" onClick={handleAddAnswer}>+</Button>
                </div>
                <div className="col text-center">
                  <Button variant="danger" className="w-50" onClick={handleRemoveAnswer}>-</Button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#17202a", borderTop: "1px solid #38444d", justifyContent: "center" }}>
          <Button variant="" className="text-white w-52" style={{ backgroundColor: "#bd2a28", boxShadow: "inset 0 0px 10px 5px #750c0c" }} onClick={() => {
            handleLogValuesEdit();
            handleCloseEdit();
          }}>
            Update Polls
          </Button>
        </Modal.Footer>
      </Modal>
       <ToastContainer/>
    </div>
  );
};

export default EditPoll;
