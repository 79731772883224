import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard/Dashboad";
import Profile from "./Pages/Profile/Profile";
import DetailPage from "./Pages/Detailpage/DetailPage";
import Data from "./Pages/Dashboard/Data";
import DataUser from "./Pages/Dashboard/DataUser";
import DataEdit from "./Pages/Dashboard/DataEdit";
import NOtFound from "./Pages/404 Not Found/404";
function App() {
  return (
    <BrowserRouter>
   
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/details/:id" element={<DetailPage />} />
        <Route path="/data" element={<Data />} />
        <Route path="/dataUser" element={<DataUser />} />
        <Route path="/dataEdit" element={<DataEdit />} />
        <Route path="*" element={<NOtFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
