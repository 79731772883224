import React from 'react'
import SideBar from '../../Components/SideBar'
import Card from '../../Components/Card'
import '../../accests/Css/Login.css'

export default function DashBoard() {
  return (
    <div style={{overflowX:"hidden", height:"100vh"}} className='bg-gray-300 '>
    <div style={{overflow:"hidden" , zIndex:"-1"}}>
    <SideBar />
    </div>
      <div   >
      <Card />
      </div>
    </div>
  )
}
