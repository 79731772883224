import React from 'react';
import { Link } from 'react-router-dom';
import '../../accests/Css/notfound.css'; 

function NotFound() {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>404</h1>
        <p>Page Not Found</p>
        <p>Oops! The page you are looking for might be in another castle.</p>
        <Link to="/" className="not-found-link">
          Go to Home
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
