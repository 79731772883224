import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { BsTrash } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../accests/Css/Sidebar.css';
import axios from "axios";
import { BaseUrl } from "../BaseUrl/Baseurl";

const UserTable = () => {
  const [user, setUser] = useState([]);
  const token = localStorage.getItem("Token");

  useEffect(() => {
    const GetUser = async () => {
      try {
        const res = await axios.get(`${BaseUrl}admin/users`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(res.data.data);
        setUser(res.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    GetUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filteredUsers = useMemo(() => user.filter(user => user.role !== 'admin'), [user]);

  const tableData = useMemo(() => {
    return filteredUsers.map(userData => ({
      id: userData.id,
      Name: (
        <span className="text-gray-500 ">
          {userData.name}
        </span>
      ),
      Email: userData.email,
      Image: <img src={userData.image} alt={`Profile of ${userData.name}`} className="w-12 h-12 object-cover rounded-full" />,
      Actions: (
        <div>
          <BsTrash onClick={() => handleActionClick('Delete', userData.id)} className="cursor-pointer text-red-500" />
        </div>
      ),
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleActionClick =  async(action, userId) => {
   
        try {
          const res = await axios.delete(`${BaseUrl}admin/users${userId}`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(res);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(`${action} clicked for user with ID ${userId}`);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "Name",
        header: "Name",
        size: 150,
      },
      {
        accessorKey: "Email",
        header: "Email",
        size: 200,
      },
      {
        accessorKey: "Image",
        header: "Image",
        size: 100,
      },
      {
        accessorKey: "Actions",
        header: "Actions",
        size: 200,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowActions: false,
  });

  return (
    <div className="app-content content lg:ml-20 bg-black " style={{ width: "90%" }}>
      <div className="content-wrapper">
        <div className="content-header row"></div>
        <div className="content-body">
          <MaterialReactTable table={table} />
        </div>
      </div>
    </div>
  );
};

export default UserTable;
