import React from 'react'
import SideBar from '../../Components/SideBar'
import '../../accests/Css/Login.css'
import Edit from '../../Components/Editpolls'

export default function DataEdit() {
  return (
    <div style={{ overflowX: "hidden", height: "100vh" }} className='bg-gray-300 '>
      <SideBar />
      <div className='lg:ml-44'>
        <Edit />
      </div>
    </div>
  )
}
